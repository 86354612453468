import React from "react"

import Layout from "../../components/layout"

const PrivacyPage = ({ location }) => {
    return (
        <Layout location={location} title={"chakimar"}>
            <article>
                <section>
                    <h1>プライバシーポリシー</h1>
                </section>
                <section>
                    <h2>情報の収集と利用</h2>
                    <p>当サイトはGoogle及びGoogleのパートナー（第三者配信事業者）の提供する広告を設置しております。その広告配信にはCookieを使用し、当サイトやその他のサイトへの過去のアクセス情報に基づいて広告を配信します。</p>
                    <p>Google が広告 Cookie を使用することにより、当サイトや他のサイトにアクセスした際の情報に基づいて、Google やそのパートナーが適切な広告を表示しています。</p>
                    <p>お客様はGoogleアカウントの広告設定ページ（https://adssettings.google.com/u/0/authenticated）で、パーソナライズ広告を無効にできます。また www.aboutads.info のページにアクセスして頂き、パーソナライズ広告掲載に使用される第三者配信事業者のCookieを無効にできます。</p>
                    <p>その他、Googleの広告におけるCookieの取り扱い詳細については、Googleのポリシーと規約ページ（https://policies.google.com/technologies/ads）をご覧ください。</p>
                </section>
                <section>
                    <h2>免責事項</h2>
                    <p>利用上の不具合・不都合に対して可能な限りサポートを行っておりますが、利用者が当サイトを利用して生じた損害に関して、開発元は責任を負わないものとします。</p>
                </section>

            </article>
        </Layout>
    )
}

export default PrivacyPage
